//////// Acacia customization /////////

.col-preview .h3-acacia {
  font-size: 4.563em;
  color: $color-clear;
}

.col-preview .b-acacia,
.mark-tech-acacia {
  color: #5366de;
}

.card-acacia {
  background-image: url("../../../assets/card-projects/acacia-mobile.jpg");
}

.svg-project-acacia {
  width: 650px;
  margin: 6vh 0 0 -9vw;
  position: absolute;
}

.slide-acacia {
  background-image: linear-gradient(
    45deg,
    #5366de 0%,
    #5366de 60%,
    transparent 60%,
    transparent 100%
  );
}

.slide-acacia-2 {
  background-image: linear-gradient(
    45deg,
    #5366de 0%,
    #5366de 27%,
    #f9a825 27%,
    #f9a825 70%,
    transparent 70%,
    transparent 100%
  ); // for laptop 45% au lieu de 70% et au lieu de 27% c'est 22%, ensuite pour les mobile je ferais du cas par cas car le svg dépasse un peu
}

@media screen and (min-width: 768px) {
  .card-acacia {
    background-image: url("../../../assets/card-projects/acacia-tablette.jpg");
  }

  .svg-project-acacia {
    width: 800px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles pour cette Media Queries */
  .svg-project-acacia {
    margin-right: -23vw;
  }

  .svg-project-acacia-2 {
    margin-right: -25vw;
  }
}

@media screen and (min-width: 1224px) {
  .card-acacia {
    background-image: url("../../../assets/card-projects/acacia.jpg");
  }

  .svg-project-acacia {
    width: 45vw;
  }

  .slide-acacia-2 {
    background-image: linear-gradient(
      45deg,
      #5366de 0%,
      #5366de 22%,
      #f9a825 22%,
      #f9a825 50%,
      transparent 50%,
      transparent 100%
    );
  }
}
