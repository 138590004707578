.nav-bg {
  @extend %navbar-slide;
  width: $max-width-percent;
  height: $max-height-vh;
  top: 0;
  right: 0;
  background-image: linear-gradient(45deg, $colorT 0%, #a10237 100%);
  background-repeat: no-repeat;
  background-position-x: right;
}

nav {
  @extend %navbar-plan;
  width: $max-width-vw;
  display: flex;
  justify-content: space-between;
  padding: 10px 3%;
  font-size: $sz10;
}

.navbar-nav {
  height: $max-height-percent;
  display: flex;
  flex-direction: column;
  column-gap: 20px;
}
nav li {
  list-style: none;
}

nav .ant-anchor .navbar-brand a {
  color: $color-dark;
}

.navbar-collapse {
  height: 12vh;
  position: absolute;
  top: 100px;
  right: 30px;
  display: flex;
  justify-content: end;
}

.navbar-collapse a {
  color: $color-clear;
  letter-spacing: 3px;
}

.navbar-toggler:active {
  animation: rotate-nav 0.3s 1 linear;
}

@media screen and (min-width: 1024px) {
  .nav-bg {
    display: none;
  }

  .navbar-collapse {
    position: initial;
  }

  .navbar-collapse a {
    text-shadow: black 0.1em 0.1em 0.4em;
    letter-spacing: 0px;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 250px;
  }

  .navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 2560px) {
  nav {
    font-size: $sz12;
  }
}
