//////// Hotel Service Plus customization /////////

.col-preview .h3-hsp {
  font-size: 2.563em;
  color: #000066;
  font-weight: bold;
}

.col-preview .b-hsp,
.mark-tech-hsp {
  color: #000066;
}

.card-hsp {
  background-image: url("../../../assets/card-projects/hsp-mobile.jpg");
}

.svg-project-hsp {
  width: 130px;
  // margin: 0 8.5vw 0 0;
  margin: 0 0 -18vh 61vw;
  position: absolute;
}

.slide-hsp {
  background-image: linear-gradient(
    45deg,
    #000066 0%,
    #000066 60%,
    transparent 60%,
    transparent 100%
  );
}

.slide-hsp-2 {
  background-image: linear-gradient(
    45deg,
    #000066 0%,
    #000066 27%,
    #eb8100 27%,
    #eb8100 70%,
    transparent 70%,
    transparent 100%
  ); // for laptop 45% au lieu de 70% et au lieu de 27% c'est 22%, ensuite pour les mobile je ferais du cas par cas car le svg dépasse un peu
}

@media screen and (min-width: 768px) {
  .col-preview .h3-hsp {
    top: initial;
    font-size: 3.563em;
    font-weight: 500;
  }

  .card-hsp {
    background-image: url("../../../assets/card-projects/hsp.jpg");
  }

  .svg-project-hsp {
    width: 800px;
    margin: 0 8.5vw 0 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles pour cette Media Queries */
  .svg-project-hsp {
    width: 250px;
    margin-right: 5vw;
  }
}

@media screen and (min-width: 1224px) {
  .card-hsp {
    background-image: url("../../../assets/card-projects/hsp.jpg");
  }

  .svg-project-hsp {
    width: 23vw;
  }

  .slide-hsp-2 {
    background-image: linear-gradient(
      45deg,
      #000066 0%,
      #000066 22%,
      #eb8100 22%,
      #eb8100 50%,
      transparent 50%,
      transparent 100%
    );
  }
}
