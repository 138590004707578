.mission {
  overflow: hidden;
  background-image: linear-gradient(
    135deg,
    $color-clear 0%,
    $color-clear 24%,
    $main-color 24%,
    $secondary-color 45%,
    $color-clear 45%,
    $color-clear 100%
  );
  strong {
    padding-bottom: 8px;
  }

  .head-mission {
    height: $max-height-vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: $sz12;
      @media screen and (min-width: 560px) {
        font-size: $sz15;
      }
      @media screen and (min-width: 763px) {
        font-size: $sz17;
      }
    }
    &-img {
      height: $half-height-vh;
    }
    &-description {
      width: $max-width-vw;
      height: $half-height-vh;
      background-color: $color-dark;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 5%;
      @media screen and (min-width: 763px) {
        height: 40vh;
      }
      p {
        text-align: center;
        font-size: $sz4;
        color: $color-clear;
        @media screen and (min-width: 763px) {
          font-size: $sz8;
        }
      }
    }
  }
}

.main-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6%;
  @media screen and (min-width: 763px) {
    padding: 2% 0;
  }
  .info {
    @media screen and (min-width: 763px) {
      display: flex;
      padding: 0 6%;
      column-gap: 5%;
    }
    ul {
      list-style: none;
    }
    h2 {
      font-size: $sz7;
      margin-bottom: 15px;
      @media screen and (min-width: 560px) {
        font-size: $sz10;
      }
    }
    p {
      // font-size: $sz3;
      // text-align: justify;
      @media screen and (min-width: 560px) {
        font-size: $sz6;
      }
    }
    .white {
      background-color: $color-clear;
      padding: 5%;
      @media screen and (min-width: 763px) {
        border-radius: $round;
        max-width: 65vw;
      }
      div {
        padding-bottom: 15px;
      }
      .aside {
        @media screen and (min-width: 763px) {
          display: flex;
          gap: 35%;
        }
      }
    }
    .black {
      background-color: $color-dark;
      color: $color-clear;
      padding: 5%;
      height: fit-content;
      @media screen and (min-width: 763px) {
        border-radius: $round;
      }
      li {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        span {
          padding-left: 6px;
        }
        &.longText {
          align-items: start;
          svg {
            padding-top: 4px;
          }
        }
      }
    }
  }

  .gallery {
    width: $max-width-percent;
    padding: 5%;
    // display: flex;
    // justify-content: flex-start;
    @media screen and (min-width: 763px) {
      // background-color: black;
      padding: 1% 6%;
    }
    h2 {
      font-size: $sz7;
      margin-bottom: 15px;
      color: $color-dark;
      @media screen and (min-width: 760px) {
        background-color: $color-clear;
        width: fit-content;
        padding: 5px 20% 5px 15px;
        border-radius: $arrondi;
        font-size: $sz10;
      }
    }
    .gallery-img {
      width: $max-width-percent;
      padding: 2%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      background-color: $color-clear;
      border-radius: $arrondi;
      @media screen and (min-width: 763px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-gap: 10px;
        padding: 1.5%;
        border-radius: $arrondi;
      }
      img {
        width: 100%;
        border-radius: $arrondi;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
        cursor: pointer;
        @media screen and (min-width: 763px) {
          max-height: 300px;
          object-fit: cover;
        }
        &:hover {
          width: 101%;
        }
        &:active {
          @media screen and (min-width: 763px) {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
            position: fixed;
            top: 7.5vh;
            left: 15vw;
            width: 70vw;
            max-height: 85vh;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

.citation-mission {
  padding: 0 4%;
  font-size: $sz11;
}

q::first-line {
  color: $color-clear;
}

.synopsis {
  height: $half-height-vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding: 0 5%;
  font-size: $sz4;
}

.synopsis strong {
  color: white;
}

.half-mission-img {
  height: 75vh;
  width: $max-width-percent;
  max-height: 987px;
  display: flex;
  justify-content: center;
}

.cd-1 {
  width: 95%;
  max-width: 1474px;
  border-radius: $arrondi;
}

.about-mission {
  height: $half-height-vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding: 0 5%;
  font-size: $sz4;
}

.github {
  padding: 7px;
  border-radius: $arrondi;
  font-size: $sz1;
  font-family: "abril", Helvetica, sans-serif;
  color: white;
  background-color: black;
  cursor: pointer;
}

.github:hover {
  animation: cardHover 0.8s 1 ease-in-out;
}

.card-mission {
  width: $max-width-percent;
}

.cd-img {
  width: $max-width-percent;
  margin: 20px 0;
}

.card-mission-img-type1 {
  height: 60vh;
}

.card-mission-img-type2 {
  height: 80vh;
}

.card-mission-img-type3 {
  height: $half-height-vh;
}

.returnHome {
  width: $max-width-percent;
  margin: 0px 0 60px 0;
  text-align: center;
  font-size: $sz11;
  a {
    color: $color-clear;
    background-color: #e3004c;
    padding: 5px 35px;
    border-radius: $round;
    &:hover {
      animation: miniBounce 1s 1 ease;
    }
  }
}

@media screen and (min-width: 763px) {
  .citation-mission {
    font-size: $sz16;
  }

  .synopsis {
    height: $half-height-vh;
    padding: 0 3% 0 3%;
    font-size: $sz5;
  }

  .about-mission {
    height: $half-height-vh;
    padding: 0 3% 0 3%;
    font-size: $sz5;
  }

  .card-mission {
    display: flex;
    justify-content: center;
  }

  .card-part-one {
    width: 45%;
    margin-right: 5%;
  }

  .card-mission-img-type1 {
    height: 60vh;
  }

  .card-mission-img-type2 {
    height: 60vh;
  }

  .card-part-two {
    width: 45%;
    display: flex;
    align-items: center;
  }

  .card-mission-img-type3 {
    height: 80vh;
  }
}

@media screen and (min-width: 1024px) {
  .main-mission {
    align-items: baseline;
  }

  .citation-mission {
    width: 68%;
  }

  .synopsis {
    height: $half-height-vh;
    padding: 0 40% 0 3%;
  }

  .about-mission {
    height: $half-height-vh;
    padding: 0 40% 0 3%;
  }

  .card-part-one {
    width: 35%;
    margin-right: 5%;
  }

  .card-mission-img-type1 {
    height: 60vh;
  }

  .card-mission-img-type2 {
    height: 60vh;
  }

  .card-part-two {
    width: 35%;
  }

  .card-mission-img-type3 {
    height: $max-height-vh;
  }
}

@media screen and (min-width: 2560px) {
  .synopsis,
  .about-mission {
    font-size: $sz12;
  }
}
