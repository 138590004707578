//////// Portfolio customization /////////

.col-preview .h3-folio {
  font-size: 3.8em;
  color: $color-clear;
}

.col-preview .b-folio,
.mark-tech-folio {
  color: #febc78;
}

.card-folio {
  background-image: url("../../../assets/card-projects/folio-mobile.jpg");
}

.svg-project-folio {
  width: 650px;
  margin: 12vh 0 0 -9vw;
  position: absolute;
}

.slide-folio {
  background-image: linear-gradient(
    45deg,
    #dd5c5c 0%,
    #dd5c5c 60%,
    transparent 60%,
    transparent 100%
  );
}

.slide-folio-2 {
  background-image: linear-gradient(
    45deg,
    #dd5c5c 0%,
    #dd5c5c 27%,
    #febc78 27%,
    #febc78 72%,
    transparent 72%,
    transparent 100%
  ); // for laptop 45% au lieu de 70% et au lieu de 27% c'est 22%, ensuite pour les mobile je ferais du cas par cas car le svg dépasse un peu
}

@media screen and (min-width: 768px) {
  .col-preview .b-folio,
  .mark-tech-folio {
    color: $color-clear;
  }

  .card-folio {
    background-image: url("../../../assets/card-projects/folio-tablette.jpg");
  }

  .svg-project-folio {
    width: 800px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .svg-project-folio {
    margin-right: -23vw;
  }
}

@media screen and (min-width: 1224px) {
  .col-preview .b-folio,
  .mark-tech-folio {
    color: #dd5c5c;
  }

  .card-folio {
    background-image: url("../../../assets/card-projects/folio.jpg");
  }

  .svg-project-folio {
    width: 40vw;
  }

  .slide-folio-2 {
    background-image: linear-gradient(
      45deg,
      #dd5c5c 0%,
      #dd5c5c 22%,
      #febc78 22%,
      #febc78 50%,
      transparent 50%,
      transparent 100%
    );
  }
}
