//////// RainyDay customization /////////

.col-preview .h3-rainy {
  font-size: 4.563em;
  color: $color-clear;
}

.col-preview .b-rainy,
.b-rainy-md,
.mark-tech-rainy {
  color: $color-dark;
}

.card-rainy {
  background-image: url("../../../assets/card-projects/rainy-mobile.jpg");
}

.svg-project-rainy {
  @extend %first-plan;
  width: 650px;
  margin: 6vh 0 0 -9vw;
}
.svg-project-rainy-2 {
  width: 660px;
  margin: 5.2vh 0 0 -11.3vw;
}

.slide-rainy {
  background-image: linear-gradient(
    45deg,
    black 0%,
    black 60%,
    transparent 60%,
    transparent 100%
  );
}

.slide-rainy-2 {
  background-image: linear-gradient(
    45deg,
    black 0%,
    black 27%,
    #e3b200 27%,
    #e3b200 72.8%,
    transparent 72.8%,
    transparent 100%
  );
}

@media screen and (min-width: 768px) {
  .card-rainy {
    background-image: url("../../../assets/card-projects/rainy-tablette.jpg");
  }

  .svg-project-rainy {
    width: 800px;
  }

  .svg-project-rainy-2 {
    width: 810px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .svg-project-rainy {
    margin-right: -23vw;
  }

  .svg-project-rainy-2 {
    margin-right: -25vw;
  }
}

@media screen and (min-width: 1224px) {
  .card-rainy {
    background-image: url("../../../assets/card-projects/rainy.jpg");
  }

  .svg-project-rainy {
    width: 45vw;
  }

  .svg-project-rainy-2 {
    width: 46vw;
  }

  .slide-rainy-2 {
    background-image: linear-gradient(
      45deg,
      black 0%,
      black 22%,
      #e3b200 22%,
      #e3b200 50%,
      transparent 50%,
      transparent 100%
    );
  }
}
