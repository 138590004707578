/*Navbar slide and rotate*/

[data-aos="nav-slide"] {
  background-size: 0%;
  &.aos-animate {
    background-size: 100%;
  }

  @media screen and (min-width: 1024px) {
    background-size: 0%;
    &.aos-animate {
      background-size: 0%;
    }
  }
}

@keyframes rotate-nav {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*Navbar fade text*/

[data-aos="nav-fade-right"] {
  opacity: 0;
  transform: translateX(-5%);
  &.aos-animate {
    opacity: 1;
    transform: translateX(0%);
  }

  @media screen and (min-width: 1024px) {
    opacity: 1;
    transform: translateX(0%);
    &.aos-animate {
      opacity: 1;
      transform: translateX(0%);
    }
  }
}

/* HEAD BACKGROUND SLIDE */

[data-aos="header-slide"] {
  background-size: 400%;
  &.aos-animate {
    background-size: 100%;
  }
}

/* ABOUT BACKGROUND SLIDE */

[data-aos="about-slide"] {
  background-size: 150%;
  &.aos-animate {
    background-size: 100%;
  }
}

/* PROJECT FOREGROUND SLIDE */

[data-aos="project-slide-f"] {
  transform: translateX(0%);
  &.aos-animate {
    transform: translateX(-100%);
  }
}

/* PROJECT BACKGROUND SLIDE */

[data-aos="project-slide-b"] {
  background-size: 300%;
  &.aos-animate {
    background-size: 100%;
  }

  @media screen and (min-width: 768px) {
    background-size: 400%;
    &.aos-animate {
      background-size: 100%;
    }
  }

  @media screen and (min-width: 1400px) {
    background-size: 300%;
    &.aos-animate {
      background-size: 100%;
    }
  }
}

/* CARD PROJECT EFFECT HOVER */

@keyframes cardHover {
  0% {
    border-radius: 10px;
  }

  50% {
    border-radius: 60px;
  }

  100% {
    border-radius: 10px;
  }
}

/* MISSION BACKGROUND SLIDE */

[data-aos="mission-slide"] {
  background-size: 400%;
  &.aos-animate {
    background-size: 100%;
  }
}

/* MISSION HOME RETURN */

@keyframes miniBounce {
  50% {
    padding-inline: 25px;
  }

  100% {
    padding-inline: 35px;
  }
}

/*contact*/

@keyframes handLink {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(-5%);
  }
}

[data-aos="contact-slide"] {
  transform: translateX(-50%);
  &.aos-animate {
    transform: translateX(8%);
  }

  @media screen and (min-width: 768px) {
    transform: translateX(-60%);
    &.aos-animate {
      transform: translateX(5%);
    }
  }

  @media screen and (min-width: 1400px) {
    transform: translateX(-50%);
    &.aos-animate {
      transform: translateX(26%);
    }
  }
}

/*POSITION RESET*/

[data-aos="position-reset"] {
  position: relative;
  z-index: 1;
  &.aos-animate {
    position: relative;
    z-index: 4;
  }
}

/* Mission IMG SLIDE LEFT */

[data-aos="img-slide-left"] {
  transform: translateX(5%);
  &.aos-animate {
    transform: translateX(0%);
  }
}

/* Mission IMG SLIDE RIGHT */

[data-aos="img-slide-right"] {
  transform: translateX(-5%);
  &.aos-animate {
    transform: translateX(0%);
  }
}

/* Mission IMG SLIDE UP */

[data-aos="img-slide-up"] {
  transform: translateY(5%);
  &.aos-animate {
    transform: translateY(0%);
  }
}
