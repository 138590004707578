.reset-contact {
  width: $max-width-percent;
  height: 94%;
}

.slide-contact {
  @extend %second-plan;
  background-image: linear-gradient(
    225deg,
    #a10237 0%,
    #a10237 60%,
    transparent 60%,
    transparent 100%
  );
}

.bg-real {
  background-repeat: no-repeat;
  background-image: linear-gradient(
    45deg,
    transparent 0%,
    transparent 90%,
    #a10237 90%,
    #a10237 100%
  );
}

.contact-main {
  width: $max-width-percent;
  height: $max-height-percent;
  padding-top: 20vh;
  padding-left: 8%;
  font-size: $sz6;
}

.contact-link {
  display: flex;
}

.email {
  color: $color-dark;
}

.hand-left {
  animation: handLink 1s infinite ease-in-out;
}

@media only screen and (min-width: 640px) and (max-width: 823px) and (min-height: 360px) and (max-height: 414px) and (orientation: landscape) {
  .contact-main {
    padding-top: 5vh;
    padding-left: 10%;
  }
}

@media screen and (min-width: 768px) {
  .contact-main {
    font-size: $sz10;
  }
}

@media screen and (min-width: 1024px) {
  .reset-contact {
    height: 95%;
  }

  .slide-contact {
    background-image: linear-gradient(
      225deg,
      #a10237 0%,
      #a10237 70%,
      transparent 70%,
      transparent 100%
    );
  }

  .contact-main {
    font-size: $sz13;
  }
}
