//////// Exval customization /////////

.col-preview .h3-exval {
  font-size: 4.563em;
  font-weight: bold;
  color: $color-clear;
}

.col-preview .b-exval,
.mark-tech-exval {
  color: #363660;
}

.card-exval {
  background-image: url("../../../assets/card-projects/exval.jpg");
}

.svg-project-exval {
  width: 100px;
  margin: -2vh 0 -11vh 63vw;
  position: absolute;
}
.svg-project-exval-2 {
  width: 100px;
  margin: -2vh 0 -18vh 63vw;
}

.slide-exval {
  background-image: linear-gradient(
    45deg,
    #363660 0%,
    #363660 60%,
    transparent 60%,
    transparent 100%
  );
}

.slide-exval-2 {
  background-image: linear-gradient(
    45deg,
    #363660 0%,
    #363660 27%,
    #e94a73 27%,
    #e94a73 70%,
    transparent 70%,
    transparent 100%
  );
}

@media screen and (min-width: 768px) {
  .card-exval {
    background-image: url("../../../assets/card-projects/exval.jpg");
  }

  .svg-project-exval {
    margin: -2vh 8vw 0 0;
  }
  .svg-project-exval-2 {
    margin: -2vh 8vw -18vh 0;
  }

  .svg-project-exval,
  .svg-project-exval-2 {
    width: 810px;
    display: block;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .col-preview .h3-exval {
    font-size: 3.563em;
  }

  .svg-project-exval,
  .svg-project-exval-2 {
    width: 230px;
    margin-right: 5vw;
  }
}

@media screen and (min-width: 1224px) {
  .col-preview .b-exval,
  .mark-tech-exval {
    color: #363660;
  }

  .card-exval {
    background-image: url("../../../assets/card-projects/exval.jpg");
  }

  .svg-project-exval {
    width: 23vw;
  }

  .svg-project-exval-2 {
    width: 23vw;
  }

  .slide-exval-2 {
    background-image: linear-gradient(
      45deg,
      #363660 0%,
      #363660 22%,
      #e94a73 22%,
      #e94a73 50%,
      transparent 50%,
      transparent 100%
    );
  }
}
