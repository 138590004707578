//fixed

%transition-plan {
  position: fixed;
  z-index: 7;
}

%navbar-plan {
  position: fixed;
  z-index: 6;
}

%navbar-slide {
  position: fixed;
  z-index: 5;
}

%notFound-plan {
  position: fixed;
  z-index: 7;
}

//absolute

%first-plan {
  position: absolute;
  z-index: 4;
}

%second-plan {
  position: absolute;
  z-index: 3;
}

%third-plan {
  position: absolute;
  z-index: 2;
}

%fourth-plan {
  position: absolute;
  z-index: 1;
}

//relative

%first-plan-r {
  position: relative;
  z-index: 4;
}

%second-plan-r {
  position: relative;
  z-index: 3;
}

%third-plan-r {
  position: relative;
  z-index: 2;
}

%fourth-plan-r {
  position: relative;
  z-index: 1;
}
