@keyframes error {
  0% {
    background-size: 300%;
  }

  100% {
    background-size: 100%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Lost {
  @extend %notFound-plan;
  height: $max-height-vh;
  width: $max-width-vw;
  padding: 15vh 0 0 50px;
  color: white;
  background-image: linear-gradient(
    135deg,
    $fourth-color 0%,
    $fourth-color 40%,
    $secondary-color 40%,
    $secondary-color 75%,
    $fourth-color 75%,
    $fourth-color 100%
  );
  animation: error 1s 1 ease-in-out;
}

.Lost p {
  max-width: 520px;
  margin: 20px 0 40px 0;
  font-size: $sz9;
}

.Lost button a {
  padding: 12px;
  font-size: $sz4;
  font-weight: bold;
  color: black;
  background-color: white;
  border-radius: $arrondi;
}

.Lost button a:hover {
  animation: cardHover 0.8s 1 ease-in-out;
}

.Lost h1,
.Lost p,
.Lost button {
  animation: fade 2s 1 ease-in-out;
}

@media screen and (min-width: 2560px) {
  /* ordinateurs */
  .Lost p {
    font-size: $sz10;
  }

  .Lost button a {
    padding: 20px;
    font-size: $sz8;
  }
}
