.head-Main {
  background-image: linear-gradient(
    45deg,
    $fourth-color 0%,
    $fourth-color 20%,
    $third-color 20%,
    $third-color 45%,
    $secondary-color 45%,
    $secondary-color 75%,
    $main-color 75%,
    $main-color 100%
  );
  h1,
  h2 {
    padding-left: 3%;
    color: $color-clear;
    font-weight: $light;
  }
}

h1 {
  font-size: $sz11;
}

h2 {
  font-size: $sz8;
}

.about {
  padding: 0 5%;
  text-align: justify;
  color: white;
  background-image: linear-gradient(
    45deg,
    $main-color 0%,
    $main-color 20%,
    $third-color 20%,
    $secondary-color 80%,
    $main-color 80%,
    $main-color 100%
  );
}

.about-plus {
  font-weight: $bold;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: $sz16;
  }

  h2 {
    font-size: $sz14;
  }

  .about {
    font-size: $sz7;
  }
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: $sz18;
  }

  .about {
    font-size: $sz9;
  }
}

@media screen and (min-width: 1224px) {
  .about {
    padding: 0 20%;
  }
}
